import type { DirectiveBinding } from 'vue';

const vPopover = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        const options = typeof binding.value === 'string' ? { title: binding.value} : binding.value;
        var settings = options.settings ?? {};
        const popover = window.bootstrap.Popover.getOrCreateInstance(el, settings);
        if (options.popoverRef) {
            options.popoverRef(popover);
        }
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
        const options = typeof binding.value === 'string' ? { title: binding.value} : binding.value;
        var settings = options.settings ?? {};
        const popover = window.bootstrap.Popover.getOrCreateInstance(el, settings);
        if (options.popoverRef) {
            options.popoverRef(popover);
        }
    },
    beforeUnmount(el: HTMLElement, binding: DirectiveBinding) {
        const options = typeof binding.value === 'string' ? { title: binding.value} : binding.value;
        const popover = window.bootstrap.Popover.getInstance(el);
        if (options.popoverRef) {
            options.popoverRef(null);
        }
        popover?.dispose();
    },
};

export default vPopover;